import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import "../styles/banner.css"
import "../styles/blog.css"
import Shapefour from "../images/bannerElement/blog.svg"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
const Category = ({ data }) => {
  const listview = data.allWpCategory.nodes
  const eachblockcontnet = data.blogblock
  const blogbannercontent = data.blogbanner
  const catlistcontent = data.catlist
  const autlistcontent = data.autlist
  const archiveyrcontent = data.archiveyr

  const [openFilter, setOpenFilter] = useState("")

  return (
    <>
      {data.allWpCategory.nodes.map(({ name, slug }) => (
        <Helmet>
          <title>{name}</title>
          <body className={slug} />
        </Helmet>
      ))}
      <Layout><div className="blogPage">
        <section id="Banner">
          <div class="container">
            <div class="banner-holder">
              <div class="images">
                <div className="img-one image-shape">
                  <img src={Shapeone} alt="Image" />
                </div>
                <div className="img-two image-shape">
                  <img src={Shapetwo} alt="Image" />
                </div>
                <div className="img-three image-shape">
                  <img src={Shapethree} alt="Image" />
                </div>
                <div className="img-four image-shape">
                  <img src={Shapefour} alt="Image" />
                </div>
              </div>
              <div class="title">
                <div class="banner-title">
                  <h1>Insights that ignite growth</h1>
                  <p>
                    Elevate your marketing game with our AI-driven expert
                    insights
                  </p>
                  <a className="button" href="/contact-us">
                    Get in touch
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section></div>
        <section className="filter-menu">
          <div className="container">
            <div className="container-width">
              <div className="wrap">
                <div className="filters-title">
                  <a
                    className="filter_expand filter-category"
                    onClick={() =>
                      setOpenFilter(
                        openFilter === "categories" ? "" : "categories"
                      )
                    }
                  >
                    Categories
                    <span
                      className={openFilter === "categories" ? "minus" : "plus"}
                    >
                      {openFilter === "categories" ? "-" : "+"}
                    </span>
                  </a>
                  <a
                    className="filter_expand filter-author"
                    onClick={() =>
                      setOpenFilter(openFilter === "authors" ? "" : "authors")
                    }
                  >
                    Author
                    <span
                      className={openFilter === "authors" ? "minus" : "plus"}
                    >
                      {openFilter === "authors" ? "-" : "+"}
                    </span>
                  </a>
                  <a
                    className="filter_expand filter-archive"
                    onClick={() =>
                      setOpenFilter(openFilter === "archive" ? "" : "archive")
                    }
                  >
                    Archive
                    <span
                      className={openFilter === "archive" ? "minus" : "plus"}
                    >
                      {openFilter === "archive" ? "-" : "+"}
                    </span>
                  </a>
                </div>
                <div className="filters-expands-list">
                  {openFilter === "categories" && (
                    <div className="fe-list categories-list">
                      <div className="category">
                        <ul>
                          {catlistcontent.nodes.map(({ name, uri }) => (
                            <>
                              <li>
                                <Link to={uri}>{name}</Link>
                              </li>
                            </>
                          ))}
                        </ul>
                      </div>
                      <span
                        className="close"
                        onClick={() => setOpenFilter("")}
                      ></span>
                    </div>
                  )}
                  {openFilter === "authors" && (
                    <div className="fe-list authors-list">
                      <ul>
                        {autlistcontent.nodes.map(({ name, uri, slug }) => (
                          <>
                            <li className={slug}>
                              <Link to={uri}>{name}</Link>
                            </li>
                          </>
                        ))}
                      </ul>
                      <span
                        className="close"
                        onClick={() => setOpenFilter("")}
                      ></span>
                    </div>
                  )}
                  {openFilter === "archive" && (
                    <div className="fe-list archive-list">
                      <span
                        className="close"
                        onClick={() => setOpenFilter("")}
                      ></span>
                      <ul>
                        {archiveyrcontent.nodes.map(({ name, uri, count }) => (
                          <li>
                            <Link to={uri}>
                              {name}
                              <span>({count})</span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="blog-overview">
          <div className="container">
            <div className="container-width clearfix">
              {data.allWpCategory.nodes.map(({ posts }) => (
                <>
                  {posts.nodes.map(
                    ({ title, blogSection, date, uri, author }) => (
                      <a href={uri} className="each-blog">
                        <div className="wrap-img">
                          <img
                            src={
                              blogSection.blogFeaturedImage.node.localFile
                                .publicURL
                            }
                            alt={blogSection.blogFeaturedImage.node.altText}
                          />
                        </div>
                        <div className="blog-wrap">
                          <p className="date">{date}</p>
                          <h2>{title}</h2>
                          <div className="author">
                            <span>By</span> {author.node.name}
                          </div>
                          <div className="blog-descp">
                            <span>Read More</span>
                          </div>
                        </div>
                      </a>
                    )
                  )}
                </>
              ))}
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

Category.propTypes = {
  data: PropTypes.object.isRequired,
}

export const data = graphql`
  query ($CategoryId: String!) {
    allWpCategory(filter: { id: { eq: $CategoryId } }) {
      nodes {
        uri
        id
        name
        slug
        posts {
          nodes {
            author {
              node {
                avatar {
                  url
                }
                name
              }
            }
            blogSection {
              blogFeaturedImage {
                node {
                  altText
                  localFile {
                    publicURL
                  }
                }
              }
            }
            date(formatString: "ll")
            title
            uri
          }
        }
      }
    }
    catlist: allWpCategory {
      nodes {
        uri
        name
      }
    }
    blogbanner: allWpPage(filter: { id: { eq: "cG9zdDoxMzc2" } }) {
      edges {
        node {
          id
          title
          bannerSection {
            bannerTitle
            heroBannerImage {
              node {
                altText
                localFile {
                  publicURL
                }
              }
            }
            mobileBanner {
              node {
                altText
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
    autlist: allWpUser {
      nodes {
        name
        uri
        slug
      }
    }
    archiveyr: allWpArchive(sort: { order: DESC, fields: name }) {
      nodes {
        name
        uri
        count
        slug
      }
    }
    blogblock: allWpPost {
      nodes {
        title
        uri
        date(formatString: "LL")
        blogSection {
          blogFeaturedImage {
            node {
              altText
              localFile {
                publicURL
              }
            }
          }
          topBannerImage {
            node {
              altText
              localFile {
                publicURL
              }
            }
          }
        }
        author {
          node {
            name
            uri
            posts {
              nodes {
                title
                uri
                slug
              }
            }
          }
        }
        categories {
          nodes {
            uri
            slug
            name
            posts {
              nodes {
                slug
                title
              }
            }
          }
        }
      }
    }
  }
`

export default Category
